<template>
  <section class="pd-20">
    <aRow :gutter="30">
      <aCol :span="18">
        <DocumentationPreview
          :documentation="documentation.details"
          :paragraphs="documentationParagraph.list"
        />
      </aCol>

      <aCol :span="6">
        <h3><i class="fa-solid fa-code"></i> Tecnologias</h3>

        <a-tag
          v-for="(tag, i) in JSON.parse(documentation.details.technologies)"
          :key="i"
          class="tag"
          >{{ tag }}</a-tag
        >
        <aDivider />

        <h3><i class="fa-solid fa-earth-africa"></i> Websites</h3>

        <a-tag
          v-for="(tag, i) in JSON.parse(documentation.details.websites)"
          :key="i"
          class="tag"
        >
          {{ tag }}
        </a-tag>
        <aDivider />

        <h3><i class="fa-solid fa-link"></i> Mais Documentações</h3>
        <div class="related-list">
          <div
            class="item"
            v-for="({ id, title }, i) in documentation.list"
            :key="i"
          >
            <a @click="$router.push(`/documentations/view/${id}`)">
              {{ title }}
            </a>
          </div>
        </div>
      </aCol>
    </aRow>
  </section>
</template>

<script>
import documentationsMixins from "@/components/documentations/mixins/documentationsMixins";
import documentationParagraphMixins from "@/components/documentations/mixins/documentationParagraphMixins";
import DocumentationPreview from "@/components/documentations/sections/DocumentationPreview.vue";

export default {
  name: "DocumentationView",
  components: { DocumentationPreview },
  mixins: [documentationsMixins, documentationParagraphMixins],
  data() {
    return {
      form: this.$form.createForm(this),
      loadingDocumentationForm: false,
      openParagraphTypes: false,
      paragraphTypes: [
        {
          name: "Texto",
          value: "text",
          ico: "font-size",
        },
        {
          name: "Texto (Info)",
          value: "info",
          ico: "info-circle",
        },
        {
          name: "Texto (Atenção)",
          value: "warning",
          ico: "warning",
        },
        {
          name: "Texto (Erro)",
          value: "error",
          ico: "close-circle",
        },
        // {
        //   name: "Código",
        //   value: "code",
        //   ico: "code",
        // },
        {
          name: "Javascript",
          value: "text/javascript",
          ico: "code",
        },
        {
          name: "PHP",
          value: "text/php",
          ico: "code",
        },
        {
          name: "HTML",
          value: "text/html",
          ico: "code",
        },
        {
          name: "CSS",
          value: "text/css",
          ico: "code",
        },
        {
          name: "Imagem",
          value: "image",
          ico: "picture",
        },
      ],
    };
  },
  computed: {
    routeTab() {
      return {
        title: `Documentação ${this.$route.params.id ?? ""}`,
        tips: "",
      };
    },
  },
  filters: {
    formatArrToStr(arr) {
      let theStr = "";
      if (arr !== undefined)
        JSON.parse(arr).forEach((item) => {
          theStr += `${item}, `;
        });
      return theStr.slice(0, -2);
    },
  },
  mounted() {
    this.getDocumentationById(this.$route.params.id).then((data) => {
      this.form.setFieldsValue(data.data);
      document.title = `${data.data.title}`;
      this.documentationParagraph.filters.documentationId = data.data.id;
      this.documentationParagraph.filters.order = "ascend";
      this.documentationParagraph.filters.orderBy = "order";
      this.getDocumentationParagraphs();
      this.getDocumentations();
    });
  },
  methods: {
    onClickAddParagraph(type) {
      this.$http
        .post("/documentation-paragraph/create", {
          user_id: this.$store.state.userData.id,
          documentation_id: this.documentation.details.id,
          type,
        })
        .then(({ data }) => {
          this.getDocumentationParagraphs();
          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: data.id,
            module: "documentation-paragraph",
            action: "create",
            description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} cadastrou um parágrafo do tipo <b>${type}</b> na documentação ID ${this.$store.state.userData.id}.`,
          });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    onSubmitForm(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.user_id = this.$store.state.userData.id;
        console.log(err, values);
        if (!err) {
          this.$http
            .post("/documentation/updatex", values)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.$http.post("/log/create", {
                user_id: this.$store.state.userData.id,
                module_id: data.id,
                module: "documentation",
                action: "create",
                description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} cadastrou uma nova documentação ID ${data.id}.`,
              });
              this.loading = false;
              this.$emit("onCreateDocumentation");
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.tag
  margin-bottom: 5px

.related-list
  .item
    margin-top: 10px
    font-size: 12px
    font-weight: 500
    a
      //color: #333
</style>
